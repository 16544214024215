import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material";

type Props = {
  wallet: React.ReactNode;
  bannerCarousel: React.ReactNode;
  gameCategoryMenu: React.ReactNode;
  mainMenu: React.ReactNode;
  depositAndWithdrawButtonGroup?: React.ReactNode;
};

export default function LayoutDashboard({
  wallet,
  bannerCarousel,
  gameCategoryMenu,
  mainMenu,
  depositAndWithdrawButtonGroup,
}: Props) {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1} columns={24}>
        <Grid item justifyContent={"center"} alignItems={"center"} width="100%">
          <Grid
            container
            columns={24}
            spacing={2}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item xs={24} md={12}>
              <Grid container columns={24} spacing={2}>
                <Grid item xs={24} md={theme.config.showDepositAndWithdrawInWallet ? 24 : 16}>
                  {wallet}
                </Grid>
                {!theme.config.showDepositAndWithdrawInWallet && depositAndWithdrawButtonGroup && depositAndWithdrawButtonGroup}
              </Grid>
              <Box mt={2}>{gameCategoryMenu}</Box>
            </Grid>
            <Grid item md={12}>
              {bannerCarousel}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={24} md={24}>
          {mainMenu}
        </Grid>
      </Grid>
    </Box>
  );
}
