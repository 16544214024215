import SaveImageButton from "@/components/ui/SaveImageButton";
import { useAuthStore } from "@/stores/authStore";
import useBankDeposit from "@/hooks/fetchers/useBankDeposit";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { blue, green, grey, orange, purple } from "@mui/material/colors";
import Image from "next/image";
import React, { useState } from "react";
import { shallow } from "zustand/shallow";
import { useTheme } from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import http from "@/libs/http";
import { useQRCode } from "next-qrcode";
import { apiPath } from "@/configs/apiPath";
import { useSnackbar } from "notistack";
import { border } from "@mui/system";
import CountdownTimer from "@/components/ui/CountdownTimer";

type Props = {};

type Inputs = {
  amount: number;
};

const schema = yup.object().shape({
  amount: yup
    .number()
    .required("กรุณากรอกจำนวนเงิน")
});

const generateQRCode = async ({
  amount
}: {
  amount: number;
}) => {
  try {
    const { data } = await http.post(apiPath.bank.qrCodeKbank, {
      "amount": `${amount}`,
    });

    return {
      status: true,
      data: data
    }
  } catch {
    return {
      status: false,
      data: null
    };
  }
};

const Deposit = (props: Props) => {
  const theme = useTheme();
  const { Image: QRImage } = useQRCode();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user] = useAuthStore((state) => [state.user], shallow);
  const [isLoading, setLoading] = useState(false)
  const [deposit, setDeposit] = useState<any>(null)
  const { data: depositBanks } = useBankDeposit();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Inputs>({ resolver: yupResolver(schema) });
  const [isExpired, setExpired] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { amount } = data;
    setLoading(true);

    try {
      if (amount <= 0) {
        setLoading(false);
        setError("amount", { message: 'ฝากเงินขั้นต่ำ 1 บาท' })
        return
      }

      const qrCode = await generateQRCode({ amount });

      if (!qrCode.status) {
        setLoading(false);
        enqueueSnackbar("something went wrong", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      } else {
        if (!qrCode.data.status) {
          setLoading(false);
          enqueueSnackbar(qrCode?.data?.msg || "", {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          setLoading(false);
          setDeposit(qrCode.data.data)
        }
      }
    } catch (err) {
      setLoading(false);
      enqueueSnackbar("something went wrong", {
        variant: "error",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    }
  };

  if (!depositBanks?.data?.find((bank) => bank.bank_code === "KBANK" && bank)) {
    return (
      <Box>
        <Divider>
          <Chip
            label="ฝากเงิน"
            color="primary"
            size="medium"
            style={{ fontSize: "18px", fontWeight: 700 }}
          />
        </Divider>
      </Box>
    )
  }

  return (
    <Box>
      <Divider>
        <Chip
          label="ฝากเงิน"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>

      {
        deposit ? (
          <Box
            bgcolor={theme.palette.background.default}
            p={2}
            borderRadius={1}
            mt={2}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={'column'}
            >
              <Box display="flex" flexDirection={"column"} alignItems={"center"}>
                <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                  <Typography variant="h6" lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                    ยอดโอนทั้งหมด
                  </Typography>
                </Box>
                <Typography variant="h3" fontWeight={"900"} lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }} mt={1}>
                  {`${deposit.request_amount} บาท`}
                </Typography>
                <Typography variant="subtitle1" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }} mt={1}>
                  ทำรายการตามที่ สแกน QR (โอนรวมทศนิยม)
                </Typography>
                <Typography variant="subtitle1" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                  QR นี้ใช้แล้วห้ามใช้โอนซ้ำ
                </Typography>
                {
                  !isExpired && (
                    <Typography variant="subtitle1" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }} mt={1}>
                      <CountdownTimer
                        setExpired={setExpired}
                        time={new Date().getTime() + (5 * 60000)}
                      />
                    </Typography>
                  )
                }
              </Box>
            </Box>
            <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
              {
                isExpired && (
                  <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
                    <Typography
                      variant="h5"
                      fontWeight={"900"}
                      sx={{
                        backgroundColor: '#e11d48',
                        padding: '0.5rem',
                        borderRadius: '0.25rem',
                        position: 'absolute',
                        marginTop: '120px',
                      }}
                    >
                      หมดอายุ
                    </Typography>
                  </Box>
                )
              }
              <QRImage
                text={deposit?.qrcode || ""}
                options={{
                  type: "image/jpeg",
                  quality: 1,
                  errorCorrectionLevel: "M",
                  margin: 3,
                  scale: 4,
                  width: 280,
                }}
              />
            </Box>
            <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
              <SaveImageButton text={deposit?.qrcode || ''} />
            </Box>
            <Box mt={2}>
              <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
                <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                  คำเตือน
                </AlertTitle>
                <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                  - กรุณาชำระเงินภายในเวลา 5 นาที
                </Typography>
                <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                  - QR code ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ หากสแกนซ้ำเข้ามา ทางเราไม่รับผิดชอบทุกกรณี
                </Typography>
              </Alert>
            </Box>
          </Box>
        ) : (
          <Box
            bgcolor={theme.palette.background.default}
            p={2}
            borderRadius={1}
            mt={2}
            component={"form"}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
              flexDirection={'column'}
            >
              <Box
                flex="1"
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Image
                  src={`https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/qrstep2_th.png`}
                  width={isMobile ? 300 : 400}
                  height={isMobile ? 93.75 : 125}
                  alt={'qrstep'}
                />
              </Box>
              <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={2}>
                <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                  <Typography variant="h6" lineHeight={1.4} align="center" sx={{ color: (theme) => theme.gradient[920] }} mr={1}>
                    ช่องทางฝากรวดเร็ว
                  </Typography>
                  <Typography className="neonTextSuccess" variant="h6" fontWeight={"500"} align="center" color={(theme) => theme.gradient[920]}>
                    {`(เข้าแน่นอน)`}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" lineHeight={1.2} align="center" sx={{ color: (theme) => theme.gradient[920] }}>
                  กรุณาใส่จำนวนเงินที่ต้องการโอน และกด "ฝากเงิน"
                </Typography>
              </Box>
              <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
                <FormControl fullWidth>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        id="username"
                        autoFocus
                        autoComplete="off"
                        inputProps={{
                          min: 0,
                          sx: {
                            textAlign: 'center',
                            fontSize: '1.25rem',
                            fontWeight: 700,
                            color: (theme: any) => theme.gradient[920],
                            borderColor: (theme: any) => theme.gradient[920],
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: "16px",
                          }
                        }}
                        sx={{
                          ...(errors.amount && {
                            borderColor: (theme) => theme.gradient[920] || "error.main",
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderRadius: "16px",
                            "& .MuiInputLabel-root": {
                              color: (theme) => theme.gradient[920] || "error.main",
                            }
                          })
                        }}
                        value={value}
                        onBlur={onBlur}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(/\D/g, "");
                          onChange(numericValue);
                        }}
                        error={Boolean(errors.amount)}
                        placeholder="จำนวนเงิน"
                      />
                    )}
                  />
                  {errors.amount && (
                    <FormHelperText
                      sx={{
                        ...(errors.amount && {
                          borderColor: (theme) => theme.gradient[920] || "error.main",
                          color: (theme) => "error.main",
                          ml: 0
                        })
                      }}>
                      {`**${errors.amount.type === "typeError" ? 'กรุณากรอกจำนวนเงิน' : errors.amount.message}`}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Box flex="1" display={"flex"} justifyContent={"start"} alignItems={"center"} mt={2} sx={{ width: '100%' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  loading={isLoading}
                  sx={{
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    color: '#fff',
                    background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                    height: "50px",
                    "&:hover": {
                      color: '#fff',
                      background: 'linear-gradient(0deg, rgba(3, 107, 34, 1) 0%, rgba(0, 255, 76, 1) 100%);',
                    },
                  }}
                >
                  ฝากเงิน
                </LoadingButton>
              </Box>
              <Box mt={2}>
                <Alert severity="warning" sx={{ backgroundColor: (theme) => theme.gradient[930] ? theme.gradient[930] : "#191207" }}>
                  <AlertTitle sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                    คำเตือน
                  </AlertTitle>
                  <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                    - กรุณาชำระเงินภายในเวลา 5 นาที
                  </Typography>
                  <Typography sx={{ color: (theme) => theme.gradient[930] ? theme.palette.getContrastText(theme.gradient[930]) : "#ffe2b7" }}>
                    - QR code ใช้ได้เพียงครั้งเดียวเท่านั้น ห้ามใช้ซ้ำ หากสแกนซ้ำเข้ามา ทางเราไม่รับผิดชอบทุกกรณี
                  </Typography>
                </Alert>
              </Box>
            </Box>
          </Box>
        )
      }



    </Box>
  );
};

export default Deposit;
