import useTransferWallet from "@/hooks/useTransferWallet";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { useEffect } from "react";

type Props = {};

const PlayingPopup = (props: Props) => {
  const { handleTransfer, loading } = useTransferWallet();
  const playingGame = localStorage.getItem("isPlayingPopup") || "";

  useEffect(() => {
    console.log("xxx");
  }, []);

  console.log("playingGame", playingGame);
  return (
    <Stack spacing={1} width="100%">
      <Typography variant="h5" textAlign={"center"}>
        คุณกำลังเล่น {playingGame}
      </Typography>
      <LoadingButton
        loading={loading}
        onClick={() => handleTransfer("OUT", playingGame.toLowerCase(), "main")}
        type="submit"
        variant="contained"
        color="error"
        fullWidth
        sx={{
          bgcolor: red[500],
          height: "50px",
        }}
      >
        กลับสู่หน้าหลัก
      </LoadingButton>
    </Stack>
  );
};

export default PlayingPopup;
