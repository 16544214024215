"use client";

import useProvidersList from "@/hooks/useProvidersList";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

type Game = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  image_newlobby: string;
  image_modify: string;
  is_lobby: boolean;
  games_lobby: any[];
};

const CATEGORY: {
  [key: string]: string;
} = {
  slot: "สล็อต",
  graph: "กราฟ",
  casino: "คาสิโนสด",
  card: "เกมไพ่",
  lottery: "หวย",
  sport: "กีฬา",
};

export default function ProvidersList() {
  const {
    groupedArray,
    isGameListLoading,
    theme,
    handleProviderClick,
    handleRightMenuClick,
    isLoggedIn,
    width,
    height,
    ItemImage,
    isActive,
    activePromotionPlatform,
  } = useProvidersList();
  const [config] = useConfigStore((state) => [state.config], shallow);

  const customAgentList = ['tiga98', 'funee8']
  const isCustomAgentPreset = customAgentList.find(agent => agent === config?.agent_name)

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          {groupedArray.map((item, index) => (
            <Grid
              id={`category-${item.category}`}
              key={`category-${item.category}`}
              item
              xs={12}
              sx={{ pt: 3, pb: 0 }}
            >
              <Typography
                variant="h5"
                sx={{ color: (theme) => theme.gradient[700] || "white" }}
              >
                {CATEGORY?.[item.category] || "~"}
              </Typography>
              <Box
                mt={-0.5}
                borderRadius={8}
                width={"6px"}
                height={"4px"}
                bgcolor={theme.palette.primary.main}
              />
              <Grid
                container
                columns={{ xs: 9, sm: 9, md: 7 }}
                spacing={1}
                justifyContent={"space-start"}
                alignItems={"center"}
              >
                {item.data.map((game) => (
                  <Grid
                    key={game.code}
                    item
                    md={1}
                    sm={3}
                    xs={3}
                    position="relative"
                  >
                    <Box
                      onClick={
                        !isLoggedIn
                          ? () => {
                            handleRightMenuClick();
                          }
                          : undefined
                      }
                    >
                      <Box
                        style={{
                          textDecoration: "none",
                          color: theme.palette.primary.main,
                          textAlign: "center",
                        }}
                        onClick={() => {
                          handleProviderClick(
                            game?.games_lobby?.[0]?.id || "",
                            game?.code || "",
                            game?.category || "",
                            game?.games_lobby?.[0]?.code || "",
                            game?.games_lobby?.[0]?.is_iframe ?? true,
                            game?.code_betflik || ""
                          );
                        }}
                      >
                        <ItemImage
                          placeholder={"blur"}
                          blurDataURL="/assets/icons/category-casino.png"
                          src={
                            isCustomAgentPreset
                              ? game.name === 'Dream Gaming'
                                ? game?.image_newlobby
                                : `https://images-platform.sgp1.cdn.digitaloceanspaces.com/gamepartners/custom/${config?.agent_name}/${game.name}.png` ||
                                "/assets/icons/category-casino.png"
                              :
                              game?.image_newlobby ||
                              "/assets/icons/category-casino.png"
                          }
                          width={width}
                          height={height}
                          alt={game.name}
                          style={{
                            width: "100%",
                            height: "auto",
                            filter:
                              isLoggedIn &&
                                isActive &&
                                !activePromotionPlatform.includes(
                                  game?.code || ""
                                )
                                ? "grayscale(90%)"
                                : "grayscale(0%)",
                          }}
                        />
                        <Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
}
