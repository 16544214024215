import { useAuthStore } from "@/stores/authStore";
import {
  Box, Chip,
  Divider,
  Typography
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { shallow } from "zustand/shallow";

type Props = {};

const UserProfile = (props: Props) => {
  const [user] = useAuthStore((state) => [state.user], shallow);
  
  return (
    <Box>
      {/* <Typography variant="h6">เติมเครดิต</Typography> */}
      <Divider>
        <Chip
          label="ข้อมูลส่วนตัว"
          color="primary"
          size="medium"
          style={{ fontSize: "18px", fontWeight: 700 }}
        />
      </Divider>
      {/* <Box mt={2}>
        <Alert severity="info" component={Box}>
          <AlertTitle>ขั้นตอนการถอนเครดิต</AlertTitle>
          1). โปรดระบุจำนวนเงินที่ต้องการถอนเครดิต โดยมีขั้นต่ำที่ <br />
          2). กด <strong>"แจ้งถอน"</strong> และรอการตรวจสอบจากแอดมิน <br />
        </Alert>
      </Box> */}
      {/* <Box mt={2}>
        <Alert severity="warning">
          <AlertTitle>คำเตือน</AlertTitle>
          ระบบจะโอนเงินเข้าบัญชีที่สมาชิกใช้สมัครเท่านั้น
        </Alert>
      </Box> */}
      <Box p={2} borderRadius={1} mt={2}>
        <Box display="flex" flexDirection={"column"} alignItems={"center"}>
          <Box
            flex="1"
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
          </Box>
          <Typography
            variant="body1"
            lineHeight={1.5}
            mt={1}
          >
            {user?.bank_name}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              เลขที่บัญชี:{" "}
            </Typography>
              {user?.account_number}
          </Typography>
          <Typography variant="body1" lineHeight={0}>
            <Typography component={"span"} color={blue[200]}>
              ชื่อ-นามสกุล:{" "}
            </Typography>
              {user?.fullname}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.username || '')}>
            <Typography component={"span"} color={blue[200]}>
              เบอร์โทร:{" "}
            </Typography>
              {user?.username}
          </Typography>
          <Typography variant="body1" lineHeight={0} onClick={() => navigator.clipboard.writeText(user?.gameusername || '')}>
            <Typography component={"span"} color={blue[200]}>
              ยูสเซอร์:{" "}
            </Typography>
              {user?.gameusername}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;
