import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, alpha } from "@mui/material";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
  borderRadius: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  "&:hover": {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.15),
  },
  ...((theme.name === "preset3" || theme.name === "preset6" || theme.name === "preset12" || theme.name === "preset13" || theme.name === "preset15" ) && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset5") && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset17" || theme.name === "preset23" || theme.name === "preset27") && {
    background: theme.gradient[610],
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
}));

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  // {
  //   key: "favorite",
  //   label: "เกมโปรด",
  //   icon: "ic:twotone-favorite",
  // },
  // {
  //   key: "hot",
  //   label: "เกมฮิต",
  //   icon: "bxs:hot",
  // },
  {
    key: "slot",
    label: "สล็อต",
    icon: "twemoji:slot-machine",
  },
  {
    key: "casino",
    label: "บาคาร่า",
    icon: "icon-park:poker",
  },
  {
    key: "lottery",
    label: "อื่นๆ",
    icon: "icon-park-twotone:sport",
  },
];

export default function GameMenu() {
  const router = useRouter();
  const handleMenuClick = (key: string) => {
    router.push(`/?category=${key}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} columns={12}>
        <Grid item xs={19}>
          <Item >
            <Grid container columns={12} spacing={2}>
              {menus.map((menu) => (
                <Grid
                  key={menu.key}
                  item
                  xs={12}
                  md={4}
                  justifyItems={"stretch"}
                  alignItems={"stretch"}
                >
                  <StyledButton
                    onClick={() => handleMenuClick(menu.key)}
                    size="large"
                    startIcon={<Icon icon={menu?.icon || ""} />}
                    variant="contained"
                    fullWidth
                    sx={{
                      color: (theme) => theme.name === 'preset14' ? "#790e50" : (theme.gradient[900] || theme.gradient[912]),
                      backgroundColor: (theme) => theme.name === 'preset14' ? "#feacc3" : "default",
                      "&:hover": {
                        backgroundColor: (theme) => theme.name === 'preset14' ? '#790e50' : 'default',
                      },
                    }}
                  >
                    {menu.label}
                  </StyledButton>
                </Grid>
              ))}
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
