"use client";

import useProvidersList from "@/hooks/useProvidersList";
import gamelist from "@/hooks/fetchers/useGameProvider";
import useLaunch from "@/hooks/useLaunch";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import {
  Box,
  Card,
  CardMedia, CardMediaProps, CircularProgress, Grid,
  Typography,
  Divider,
  Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import { Login } from "@/sections";
import { Logo } from "../components/logo";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ItemImage = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
  borderRadius: "16px",
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    cursor: "pointer",
  },
}));

export default function GamesListProvider(props: { provider: string }) {
  const { theme, handleRightMenuClick, isLoggedIn } = useProvidersList();
  const { launchGame } = useLaunch();
  const { data: gameList, isLoading: isGameListLoading } = gamelist(
    props.provider
  );
  const [config] = useConfigStore((state) => [state.config], shallow);

  if (!isLoggedIn) {
    return (
      <Paper elevation={1}>
        <Box p={2}>
          <Box
            mb={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Logo
              src={config?.logo || ""}
              height={256}
            />
          </Box>
          <Box mb={3}>
            <Divider>
              <Typography variant="h5" align="center">
                เข้าสู่ระบบ
              </Typography>
            </Divider>
          </Box>
          <Box mt={2}>
            <Login />
          </Box>
        </Box>
      </Paper>
    )
  }

  if (isGameListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ pt: 3, pb: 0 }}>
            <Typography variant="h6">
              {props.provider}
            </Typography>
            <Box
              mt={-0.5}
              borderRadius={8}
              width={"6px"}
              height={"4px"}
              bgcolor={theme.palette.primary.main}
            />
          </Grid>
          {gameList?.map((game: any, key: number) => {
            if (!game?.img_custom) {
              return (
                <Grid key={key} item md={2} xs={4}>
                  <Item>
                    <Card>
                      <ItemImage
                        component="img"
                        image={game?.img}
                        title={game?.name}
                        onClick={() => {
                          return !isLoggedIn
                            ? handleRightMenuClick()
                            : (game?.agent_name === 'BETFLIK')
                              ? launchGame(game?.id || "", isMobile, game?.is_iframe)
                              : launchGame(game?.id || "", isMobile)
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                      color={"white"}
                    >
                      {game?.name ?? "N/A"}
                    </Typography>
                  </Item>
                </Grid>
              );
            } else {
              return (
                <Grid key={key} item md={2} xs={4}>
                  <Item>
                    <Card>
                      <ItemImage
                        component="img"
                        image={game?.img_custom}
                        title={game?.name}
                        onClick={() => {
                          return !isLoggedIn
                            ? handleRightMenuClick()
                            : (game?.agent_name === 'BETFLIK')
                              ? launchGame(game?.id || "", isMobile, game?.is_iframe)
                              : launchGame(game?.id || "", isMobile)
                        }}
                      />
                    </Card>
                    <Typography
                      sx={{
                        mt: 1,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                      color={"white"}
                    >
                      {game?.name ?? "N/A"}
                    </Typography>
                  </Item>
                </Grid>
              );
            }
          })}
        </Grid>
      </Box>
    )
  }
}
